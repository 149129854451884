$spacing-xs: 5px;
$spacing-sm: 10px;
$spacing-md: 15px;
$spacing-lg: 20px;
$spacing-xl: 25px;

:root {
  --mat-table-row-item-label-text-size: 14px;
  --mat-menu-item-label-text-size: 14px;
  --mat-form-field-container-text-size: 14px;
  --mat-form-field-container-vertical-padding: 12px;
  --mat-checkbox-label-text-size: 14px;
  --mat-select-trigger-text-size: 14px;
  --mdc-list-list-item-label-text-size: 14px;
  --mat-option-label-text-size: 14px;
  --mdc-snackbar-supporting-text-size: 14px;
}
