
@use '@angular/material' as mat;


// Dark/Light theme defined here
@import './buttons';

$border-radius: 20px;
$border-color: rgb(105, 105, 105);
$selected-background-color: rgb(225, 225, 225);
$cb-blue: #1f69ff !default;

.mat-mdc-dialog-surface {
   // Previous angular version had padding here. This is to keep the same look
   // until all dialogs have been updated
  padding: 16px 24px 8px;
}
mat-horizontal-stepper {
  &.hide-header {
    .mat-horizontal-stepper-header-container {
      display: none !important;
    }
  }

  &.p-0 {
    .mat-horizontal-content-container {
      padding: 0 !important;
    }
  }

}

mat-tab-group.hide-tabs {
  > .mat-mdc-tab-header {
    display: none;
  }
}

mat-radio-group {
  width: 100%;

  mat-radio-button.w-100 {
    .mat-radio-label-content {
      width: 100%;
      white-space: normal;
    }
  }

  .mdc-label {
    margin:0px;
  }
}

.mat-mdc-chip.mini-chip,
.mat-mdc-chip.sm {

  @include mat.chips-density(-2);
  font-size: 12px !important;
  opacity: 1 !important;
}

.mat-mdc-chip.xs {
  @include mat.chips-density(-4);
}

mat-radio-button {
  &.hide-ripple {
    .mat-radio-ripple {
      display: none;
    }
  }
}

lc-textbox,
lc-phone-number,
lc-dropdown,
lc-multiselect,
lc-textarea,
lc-agent-search {
  width: 100%;

  .mat-mdc-form-field.mat-form-field-readonly {
    @extend .mat-form-field-disabled;
  }

  &.hide-hint {
    .mat-mdc-form-field-subscript-wrapper {
      height: 0; // for the new angular 19 controls
    }
  }
}


.mat-icon-button.no-focus ::ng-deep .mat-button-focus-overlay {
  display: none;
}


.mat-mdc-list.height-auto, .mat-mdc-list.height-auto {
  .mat-mdc-list-item, .mat-mdc-list-item {
    height: unset;
    white-space: normal;
    margin: 0;
  }
}

/*
* Hide the arrow if elements are selected
*/
mat-form-field.selected {
  .mat-select-arrow {
    color: transparent !important;
  }
}

mat-option.line-height-unset {
  line-height: unset !important;
}

/**
 * This mixin is to make it easier to update the borders on the form-field
 * Currently it is only being used by this card-select.component.ts, but
 * if it is needed elsewhere, put it in the lc-material.scss
*/
@mixin formFieldBorders($borderStyle) {
  &.mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-top-style: $borderStyle;
    border-left-style: $borderStyle;
    border-bottom-style: $borderStyle;
    border-width: 1px;
  }

  &.mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-top-style: $borderStyle;
    border-right-style: $borderStyle;
    border-bottom-style: $borderStyle;
    border-width: 1px;
  }

  &.mat-form-field-appearance-outline .mat-form-field-outline-gap {
    border-bottom-style: $borderStyle;
    border-width: 1px;
  }
}

.hide-form-borders {
  mat-form-field:not(.mat-form-field-invalid) {
    @include formFieldBorders(none);
  }
  mat-form-field.mat-form-field-invalid {
    @include formFieldBorders(dashed);
  }
}

.mat-drawer-container {
  height: 100%;
  width: 100%;
  margin-top: 2px;
  background-color: #f2f5f6;
  .mat-drawer {
    overflow-x: hidden;
    .mat-drawer-inner-container {
      width: 100%;
      height: 100%;
      overflow-y: auto;

      box-sizing: content-box;

      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }
      /* Hide scrollbar for IE and Edge */
      -ms-overflow-style: none;
    }
  }
}

.overlay-container {
  position: relative;

  .overlay {
    position: absolute;

    &.top-right {
      top: 5px;
      right: 5px;
    }
  }
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(245, 245, 245, 0.75);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*
* Styles for the material-table
*/
.table-container {
  width: 100%;
  position: relative;

  .loading-overlay.with-pager {
    bottom: 56px; // 56px from the bottom so that it doesnt cover up the paginator
  }

  .table-wrapper {
    position: relative;
    overflow: auto;
    min-height: 50px; // Large enough for the spinner

    table {
      width: 100%;
    }
  }

  .mat-column-action,
  .mat-column-actions {
    text-align: right;
    &.mat-header-cell {
      text-align: center;
    }
  }
}

@mixin mat-icon-size($size) {
  height: $size !important;
  width: $size !important;
  line-height: $size !important;
  font-size: $size !important;
}

.mdc-icon-button {
  outline: none !important;

  &.sm {
    height: 24px;
    width: 24px;
    line-height: 24px;
    padding: 1px;
    .mat-icon {
      @include mat-icon-size(20px);
    }
  }

  &.xs {
    height: 20px;
    width: 20px;
    line-height: 20px;
    padding: 1px;
    .mat-icon {
      @include mat-icon-size(16px);
    }
  }
}

.mat-icon-button.large {
  width: 48px;
  height: 48px;
  line-height: 48px;

  .mat-icon {
    @include mat-icon-size(48px);
  }
}

.mat-icon.xs {
  @include mat-icon-size(14px);
}

.mat-icon.small, .mat-icon.sm {
  @include mat-icon-size(18px);
}

.mat-icon.md {
  @include mat-icon-size(24px);
}

.mat-icon.large,
.mat-icon.lg {
  @include mat-icon-size(48px);
}

.mat-icon.xl {
  @include mat-icon-size(62px);
}

.mat-icon.size-34 {
  @include mat-icon-size(34px);
}

mat-form-field {
  // have the formfield take up full width
  width: 100%;
}

/*
* Style for the "Dropdown Header" which is actually just a disabled option
*/
mat-option.header-option {
  line-height: 1em !important;
  height: 2.5em !important;
  font-weight: bold;

  .mat-option-text {
    line-height: 1em;
  }
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: $cb-blue;
}


.cdk-overlay-pane.p-0 {
  .mat-mdc-dialog-container, .mat-mdc-dialog-surface {
    padding: 0 !important;
  }
}

.cdk-overlay-pane:has(.mat-mdc-select-panel) { min-width: fit-content; }

.date-filter {
  max-height: unset!important;
  background: white;

  #option-custom {
    height: unset;
    display: block;
  }
}

.hide-notched-outline {
  .mdc-notched-outline {
    display: none;
  }
}
