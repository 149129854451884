.mat-mdc-menu-panel {
  &.p-0 {
    .mat-menu-content {
      padding-top: 0!important;
      padding-bottom: 0!important;
    }
  }
  &.height-auto {
    min-height: unset;
    .mat-menu-item {
      height: unset;
      white-space: normal;
      padding: 10px 16px;
    }
  }
  // Allow the elements within the decide how big
  &.w-100 {
    min-width: unset;
    max-width: 100%;
  }
}
