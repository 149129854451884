/*
* IMPORTANT: Only import this file once!
*/

/**
* Standard Imports
*/
@use 'sass:map';
@use '@angular/material' as mat;
@use '@abt-desk/realogy-mat-theme/src/palettes' as palettes;
@use './colors' as colors;
@use '../typography' as typography;
/*
* IMPORTANT: Only include these once
*/
@include mat.core();

/**
* Palettes
*/
$primary-palette: mat.m2-define-palette(palettes.$cb-palette);
$accent-palette: mat.m2-define-palette(palettes.$cb-palette, 300);
$warn-palette: mat.m2-define-palette(palettes.$cb-warning, 500);

$dark-primary-palette: mat.m2-define-palette(palettes.$cb-palette, 300);
$dark-accent-palette: mat.m2-define-palette(palettes.$cb-palette, 300);
$dark-warn-palette: mat.m2-define-palette(palettes.$cb-warning, 300);


/**
* Typography
* default config values can be found here:
* https://github.com/angular/components/blob/4771931c2f334be1edc2b7764921d6539808317e/src/material/core/m2/_typography.scss#L183
*/
$lcc-font-family: 'Roboto, monospace';
$lcc-typography: typography.$default-typography;

$light-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $primary-palette,
      accent: $accent-palette,
      warn: $warn-palette,
    ),
    typography: $lcc-typography,
    density: -1,
  )
);

body {
  @include mat.form-field-density(-2);
}

$dark-theme: mat.m2-define-dark-theme((
  color: (
    primary: $dark-primary-palette,
    accent: $dark-accent-palette,
    warn: $dark-warn-palette,
  ),
  typography: $lcc-typography,
  density: 0,
));


// Define the light theme by default
@include mat.all-component-themes($light-theme);
@include mat.typography-hierarchy($lcc-typography);
@include colors.colors($light-theme);


/**
* Class Styles
*/
.dark-theme {
  // This mixin only generates the color styles now.
  @include mat.all-component-colors($dark-theme);

  :not([class*=template]) {
    @include colors.colors($dark-theme);

    .summary-card {
      // color:#222;
      background-color: #424242;
      color: white;

      .mat-button, .mat-button-base {
        color:white;
      }
    }

    .mat-mdc-card, .mat-header-cell, .mat-calendar-table, .mat-cell,
    .side-nav, .mat-column-title, .mat-column-product-action,
    .mat-column-product-details-conciergeObligation, .mat-column-product-status, .mat-mdc-table, .mat-autocomplete-panel,
    .mat-row, .content-container, .mat-stepper-vertical, .bg-card, .form-control, .mat-menu-content, .mat-expansion-panel, .mat-flat-button, .mat-mini-fab, .mat-select-panel, .mat-mdc-card-actions {
      // color:#222;
      background: #424242 !important;
      color: white;
    }

    .green-icon {
      background: #4DAF50;
    }

    .red-icon {
      background: #DE342B;
    }

    .mat-calendar-table {
      .mat-calendar-arrow, .mat-calendar-table-header, .mat-calendar-body-cell-content, .mat-calendar-body-label {
        color: white;
      }
    }
    .mat-mdc-button, .mat-pseudo-checkbox-full, .mat-option, .mat-option.mat-active {
      color: white;
    }

    .mat-mdc-table {
      .mat-icon {
        color: white;
      }

      .mat-warn {
        color: #de342b
      }
    }

    .cdk-global-overlay-wrapper {
      color: rgba(255, 255, 255, 0.5);
    }

    .mat-button-toggle-button {
      color: rgba(255, 255, 255, 0.5);
    }

    .mat-button-toggle-checked {
      background-color: #212121 !important;
      color: rgba(255, 255, 255, 0.7);
    }
    .page-container,.mat-mdc-chip, .content-container, .mat-row, .page-content, .page-background, .header, .sidenav, .mat-mdc-dialog-container, .layout-container, .mat-drawer, .mat-drawer-inner-container,.mat-mdc-tab-header {
      background-color: #303030 ;
      color: white;
    }

    .mat-mdc-list,span .mat-mdc-list-item-content, .mat-step-label, .mat-step-icon, .mat-menu-submenu-icon {
      color: #ffff !important
    }

    .mat-step-icon-selected{
      background-color:#6296ff !important;
      color: #ffff !important
    }

    mat-select, mat-list-item .mat-button-disabled, .bg-light-card{
      color: #ffff !important
    }

    .mat-icon {
      overflow: inherit !important;
    }

    .mat-input-element::placeholder, .mat-select-trigger, .mat-select-arrow,mat-checkbox .checkbox-frame{
      color: #ffff !important
    }

    .mat-focused .placeholder,.mat-select-arrow-wrapper ,.mat-checkbox-input, input .mat-input-element,.mat-column-title , .mat-select-placeholder,.mat-select-value-text,.mat-form-field-autofill-control,.cdk-text-field-autofill-monitored, .mat-input-element, .cdk-column-title, .mat-hint, .text-faded, .text-default, .header-container, .addres, .mat-caption, .package-title, .package-price, .mat-mdc-list-item, .mat-mdc-list-item-content, .mat-mdc-tab-label, .mat-input-element, .header-containe, .mat-body-1, .mat-body-2, .mat-menu-item {
      color:#ffff !important;
    }

    a.disabled {
      color: #fff !important;
    }

    .mat-mdc-tab-label-active {
      color: #1f69ff;
      font-weight: 700;
      opacity: 1;
    }

    .mat-mdc-tab-link {
      background-color: #303030;
      opacity: 1;
      color: #fff;
    }

    .router-background, .banner, .welcome-message, .info-container {
      background-color: #303030;
      color: #fff;
    }

    .greetings-subheader{
      font-size: 24px;
      line-height: 32px;
      color:#fff;
    }

    #home-button {
      color:#fff;
    }

    .nav-button.selected {
      border-left-color: grey;
    }

    .highlight {
      background: #303030;
    }

    // ONLY a dark theme override for bootstrap
    $color-config: mat.m2-get-color-config($dark-theme);
    $dark-foreground-palette: map.get($color-config, foreground);

    .cancel-btn,
    .cancel-btn:hover {
      color:rgba(0, 0, 0, 0.87);
    }

    .gallery-background {
      background-color: #424242;
    }

    .rsc-header, .rsc-user-menu,.rsc-waffle-wrapper,.waffle-hover-box {
      background-color: #424242;
      color: #ffffff;
      border-color: #424242 !important;
      a, div, i, mat-icon{
          color: #ffffff !important;
        }
    }

    .rsc-user-menu {
      box-shadow: inset 0 11px 3px -10px #424242;
    }

    lc-footer {
      background-color: #424242;
      .helpdesk-info, .helpdesk-links span,.social-media-links{
        a,span,i{
          color: #ffffff !important;
          border-color: #ffffff !important;
        }
      }
    }

    .field-label, .mat-form-field-label {
      color: #ffffff !important;
    }

    .selected-outline, .print-container, .box-border  {
      background-color: #424242;
    }

    .active-templ:hover, .highlight {
      background-color: #303030;
    }
  }
}

.light-theme {
  :not([class*=template]) {
    // NOT: This theme variable comes from the abt
    @include colors.colors($light-theme);
  }

  .header {
    background-color: #f2f2f2 !important;
    color: #2B2E30;
  }

  .page-content, .router-background, .page-background, .banner {
    background-color: #fcfcfc;
  }

  .mat-title {
    color: #727476;
  }

  .router-background, .banner {
    background-color: #fff;
  }

  .banner {
    padding-top: 20px;
    background-color: #F2F2F2;
  }

  .mat-header-cell{
    background-color: #E9E9E9;
    font-weight: bold;
    color: #2B2E30;
  }

  .table-container-wrapper {
    background-color: #fff;
    border-color: #E9E9E9;
  }

  .highlight{
    background: whitesmoke;
  }
}
