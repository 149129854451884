.lc-select-option-outlined {
  border-color: var(--mdc-outlined-text-field-outline-color, var(--mat-sys-outline));
  border-width: var(--mdc-outlined-text-field-outline-width, 1px);
  border-style: solid;
  border-radius: var(--mdc-outlined-text-field-container-shape, var(--mat-sys-corner-extra-small));

  &.selected {
    border-color: var(--mdc-outlined-text-field-focus-outline-color);
    border-width: var(--mdc-outlined-text-field-focus-outline-width, 2px);
  }
}
